.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;

  .card-body {
      padding-left: 5px;
      padding-right: 5px;
  }

  img {
      border-radius: $border-radius-extreme;
  }
}

.card-gift {
  height: 90%;
}
