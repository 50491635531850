.card-product {
  text-align: center;

  margin: 3em 0 3em 0;
  background: rgba(0, 0, 0, 0.3);

  .author {
    float: left;
    color: $light-gray;
  }

  .stats {
    color: $light-gray;
  }

  &.odd {
    background: rgba(255, 255, 255, 0.3);

    * {
      color: $font-color;
    }
  }

  .card-image {
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .product-table {
    margin: 0;

    th {
      padding-top: 0;
      color: $dark-gray;
      border: none;
    }

    td {
      color: $light-gray;
      padding: 0 1em;
      border: none;
    }
  }

  .card-description {
    min-height: 100px;
    font-style: italic;
  }

  @media (min-width: 992px) {
    &.card-product-enterprise {
      .card-title {
        min-height: 60px;
      }

      .card-description {
        min-height: 250px;
      }
    }
  }
}


