.register-title {
  position: absolute;
  width: 100%;
  height: 120px;
  top: 0px;
  left: 0px;
  z-index: -1;
  filter: opacity(20%);

  border-radius: 8px 8px 0 0;
}

.register-content {
  position: absolute;
  width: 100%;
  height: calc(100% - 110px);
  top: 0px;
  left: 0px;
  z-index: 0;
  margin-top: 110px;
  background-position-y: -120px;

  border-radius: 0 0 8px 8px;
}

.no-padding-bottom {
  padding: 0;
}

.services-section-description{
  position: relative;
  top: -120px;
}

.card-service {
  background-color: transparent;
  border-radius: 8px;
  color: #fff;
  max-width: 350px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 0px;
  position: relative;
  top: -140px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    color: #B33C12;
    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }

  .card-blurry-title {
    padding: 20px;
    border-radius: 8px 8px 0 0;
    backdrop-filter: saturate(50%) blur(10px);
    color: white;
    min-height: 120px;
    max-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-title-main-page {
    color: $light-gray;
    font-weight: 500;
    text-shadow: #000 1px 0px 5px;
    margin: 0;
  }
}
