.google-map {
  width: 100%;
  height: 60vh;
}

.map-pin-icon {
  font-size: 3rem;
  -webkit-text-stroke: 1px #fff;
  position: absolute;
  top: -3rem;
  left: -1.5rem;
  color: lighten($ea-theme-main, 15%);
  filter: drop-shadow(0px 0px 2px #fff);
}

.pin {
  color: #476d83;
  background-color: #E8E7E3a0;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 4px;
  backdrop-filter: saturate(50%) blur(2px);
  padding: 1em;
  display: flex;
  align-items: center;
  width: 370px;
  position: relative;
  left: -185px;
  top: 1em;
  filter: drop-shadow(0px 0px 2px #333);

  &:hover {
    filter: drop-shadow(0px 0px 2px #fff);
    color: #fff;
    background-color:  rgba($ea-theme-main, 0.8);
    transition: all 0.25s ease-in-out;

    & + i {
      color: $ea-theme-main;
      filter: drop-shadow(0px 0px 2px #fff);
      transition: all 0.25s ease-in-out;
    }
  }

  .touch-icon{
    font-size: 1.5em;
    align-self: flex-start;
    position: absolute;
    right: 0.5em;
  }
}

.map-pin-text {
  font-weight: normal;
  padding-left: 1em;
}

.link-gmap {
  color: $success-color;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }
}
