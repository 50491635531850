.card-testimonial{
  text-align: center;
  background: rgba(0,0,0,0.3);

  &.odd {
    background: rgba(255,255,255,0.3);
    color: #0b1011;
  }

  .card-stars {
    color: bisque;
    margin: 1em 0;
  }

  .card-description {
    font-style: italic;
  }

}
