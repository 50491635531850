.modal-header {
    font-size: 1.5rem;
    color: $card-subtitle-blue;
    border-bottom: 1px solid $medium-gray;
    padding: 20px;
    text-align: center;
    display: block !important;

    &.no-border-header{
        border-bottom: 0 none !important;
        & .modal-title{
            margin-top: 20px;

        }
    }
    button.close{
      &:focus {
        outline: none;
      }
    }
}
.modal-dialog{
    &.modal-sm,
    &.modal-register{
        .modal-header{
            button.close{
                margin-top: 0;
            }
        }
    }
}

.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    .modal-header{
        h6{
            margin-top: 10px;
        }
    }
}

.modal-dialog {
    padding-top: 60px;
}

.modal-body{
    padding: 20px 50px;
    color: #000;
    box-shadow: 0px 10px 20px #062031;
}

.modal-section-dark {
    background-color: $ea-them-background-dark;

    .opening-hours {
        padding: 1em 2em;
        display: flex;
        flex-direction: column;

        .opening-day {
            float: left;
        }
        .opening-hour {
            float: right;
        }
    }

}

.modal-footer {
    border-top: 1px solid $medium-gray;
    padding: 1em 0;
    background: lighten($ea-theme-main-background, 10);

    &.no-border-footer{
        border-top: 0 none;
    }

    .row {
        align-items: center;
    }

    .phone-button{
        font-weight: 600;
        border-width: 1px;
    }

    .btn {
        min-width: 290px;
    }
}

.modal-footer .left-side, .modal-footer .right-side{
    display: inline-block;
    text-align: center;
    width: 49%;
}
.modal-footer .btn-link{
    padding: 20px;
    width: 100%
}
.modal-footer .divider{
    background-color: $medium-gray;
    display: inline-block;
    float: inherit;
    height: 63px;
    margin: 0px -3px;
    width: 1px;
}

.modal-register .modal-footer{
    text-align: center;
    margin-bottom: 25px;
    padding: 20px 0 15px;
    span{
        width: 100%;
    }
}
.modal-header:after {
     display: table;
     content: " ";
}
.modal-header:before{
    display: table;
    content: " ";
}
