.landing-alert {
  margin-bottom: 0;
}

.page-header {
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 50px 50px #010609;

  .filter::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &.page-header-small {
    min-height: 65vh !important;
    max-height: 700px;
  }

  &.page-header-xs {
    min-height: 40vh !important;
  }

  &.section-dark {
    .content-center,
    .category-absolute {
      z-index: 1;
    }
  }
}

.page-header .motto {
  text-align: left;
  z-index: 3;
  color: #fff;
  position: relative;

  @media (min-width: 768px) {
    .loop-text {
      padding-left: 4em;
    }
  }

  @media (max-width: 768px) {
    .loop-text {
      text-align: center;
    }

    .sub-motto {
      white-space: pre-wrap;
      text-align: center;
    }
  }
}

.page-header .title-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.landing-section {
  padding: 100px 0;
  z-index: 1;
}

.section-buttons {
  z-index: 1;
}

.landing-section .btn-simple {
  padding: 0;
}

.landing-section .column {
  padding: 0 75px 0 25px;
}

.team-player .img-circle, .team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}

.contact-form label {
  margin-top: 15px;
}

.contact-form .btn {
  margin-top: 30px;
}

.navbar-relative {
  position: relative;
  z-index: 2;
}

#register-navbar a {
  color: #FFF;
}

.register-background .container {
  margin-top: 11%;
  position: relative;
  z-index: 3;
}

.register-footer {
  bottom: 20px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  color: $white-color;
}

.register-footer .fa-heart {
  color: #EB5E28;
}

.register-card label {
  margin-top: 15px;
}

.register-card .title {
  color: #B33C12;
  text-align: center;
}

.register-card .btn {
  margin-top: 30px;
}

.register-card .forgot {
  text-align: center;
}

.owner {
  text-align: center;
}

.owner .avatar {
  padding: 15px;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block;
}

.owner .name h4 {
  margin-top: 10px;
}

.profile-tabs {
  margin: 50px 0;
  min-height: 300px;
}

#following h3 {
  margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox {
  margin-top: -15px;
}

#follows .follows h6 {
  margin-top: 15px;
}

.follows hr {
  margin-top: 10px;
}

.alert h5 {
  // margin-bottom: 10px;
}

.title-brand {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #FFFFFF;
  display: block;

  .type {
    position: absolute;
    font-size: 20px;
    background: #132026;
    padding: 6px 10px;
    border-radius: 4px;
    top: 0;
    font-weight: 600;
    margin-top: 10px;
    right: -15px;
  }
}

.presentation-title {
  font-size: 8em;
  line-height: 1.2;
  font-weight: 100;
  margin: 0;
  color: #FFFFFF;
  background: #fbf8ec;
  background: -moz-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: -webkit-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: -ms-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
  background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.presentation-subtitle {
  font-size: 1.7em;
  color: #FFFFFF;
}

.category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);

  .creative-tim-logo {
    max-width: 140px;
    top: -2px;
    left: 3px;
    position: relative;
  }
}

.fog-low {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -35%;
  margin-bottom: -50px;
  width: 110%;
  opacity: .85;

  img {
    width: 100%;
  }

  &.right {
    margin-left: 30%;
    opacity: 1;
  }
}

.page-header {
  .content-center {
    margin-top: 85px;
  }
}

.moving-clouds {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 250.625em;
  height: 43.75em;
  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0)
  }
}

.cgv {
  img {
    max-width: 70px;
  }

  .row {
    margin-bottom: 2em;
  }
}


.profile-content {
  & .tab-content {
    & .tab-pane {
      min-height: 200px;
    }

    & #tweets, #connections, #media {
      height: 100%;
    }
  }
}

.logo-animation {
  will-change: filter;
  transition: filter 0.3s ease-out;
  filter: drop-shadow(0px 0px 2px #fff);
}

.logo-animation:hover {
  filter: drop-shadow(0px 0px 0px #fff);
}

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.carousel-fade .carousel-item.active {
  opacity: 1;
}

carousel,
.carousel-inner,
.carousel-item {
  min-width: 100%;
  min-height: 100%;

  img {
    object-fit: cover;
    height: 100vh;
  }

}

.carousel {
  position: absolute;
  height: 100vh;
  width: 100%;
}
